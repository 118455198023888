<template>
  <div class="page-users-list">
    <n-card :loading="$var('load')">
      <div class="table-tools">
        <n-divide>
          <div>
            <div class="dates-title">Фильтровать статистику по дате</div>
            <div class="dates">
              <n-datepicker title="От" :value.sync="startedAt" />
              <n-datepicker title="До" :value.sync="endedAt" />
            </div>
          </div>
          <div><n-button @click="$var('create', true)">Новый пользователь</n-button></div>
        </n-divide>
      </div>
      <n-table :data="users" :columns="columns">
        <template #tools="{item}">
          <n-link :to="{name: 'users.index', params: { id: item.id, }}"><n-button icon="pen" round /></n-link>
        </template>
      </n-table>
    </n-card>

    <modal-users-create v-if="$var('create')" @reload="loadManagers" @close="$var('create', false)" />
  </div>
</template>

<script>
import ModalUsersCreate from './Create'

export default {
  name: 'PageUsersList',
  components: { ModalUsersCreate, },
  data: () => ({
    users: [],
    columns: [
      { name: 'fullName', title: 'ФИО', },
      { name: 'role.title', title: 'Роль', },
      { name: 'statsAllTime.activeCount', title: 'Заявки в работе', },
      { name: 'statsAllTime.sentCount', title: 'Количество отправленных КП', },
      { name: 'statsAllTime.finishedCount', title: 'Количество полученных РО', },
      { name: 'statsAllTime.applicationsCount', title: 'Всего заявок', },
      { name: 'tools', title: '', },
    ],
    startedAt: '',
    endedAt: '',
  }),
  watch: {
    startedAt() {
      this.loadManagers()
    },
    endedAt() {
      this.loadManagers()
    },
  },
  mounted() {
    this.loadManagers()
  },
  methods: {
    loadManagers() {
      this.$var('load', true)
      const api = $api.auth.users.get().with('statsAllTime').with('role').all()
      if (this.startedAt) api.query({ startedAt: this.startedAt, })
      if (this.endedAt) api.query({ endedAt: this.endedAt, })
      api.then((response) => {
        this.users = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-users-list {
  .dates-title {

  }
  .dates {
    display: flex;
    margin-bottom: 20px;
    .n-datepicker {
      margin-right: 10px;
    }
  }
}
</style>
