<template>
  <div class="orders-list">
    <n-card>
      <div class="filters">
        <n-search :value="params.filter.number" placeholder="Поиск по заявке (номер, PO) " @update:value="search" />
        <n-select placeholder="Менеджер" :data="managers" :value.sync="manager"
                  item-value="id" option-title="fullName" selected-title="fullName" @update:value="selectManager" />
        <n-select placeholder="Статус" :data="statuses" :value.sync="status" item-value="name" @update:value="selectStatus" />
      </div>
      <n-data-component ref="data" :api="api" :data.sync="orders" :loading.sync="loading" v-bind="params" />
      <n-table :data="orders" :columns="columns" :loading="loading">
        <template #createdAt="{item}">
          {{ $app.date.format(item.createdAt) }}
        </template>
        <template #endedAt="{item}">
          {{ $app.date.format(item.endedAt, 'date') }}
        </template>
        <template #headerCell="{column}">
          <div class="header-cell">
            {{ column.title }}
            <n-button v-if="column.sort" flat round icon="arrows-alt-v" @click="sort(column.name)" />
          </div>
        </template>
        <template #status="{item}">
          {{ $n.status(item.status).title }}
        </template>
        <template #tools="{item}">
          <n-link :to="{name: 'orders.index', params: { id: item.id, }}">
            <n-button icon="eye" round />
          </n-link>
        </template>
        <template #delete="{item}">
          <n-button flat round icon="trash" @click="deleteOrder(item.id)" />
        </template>
      </n-table>
    </n-card>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data: () => ({
    loading: false,
    params: {
      filter: {
      },
    },
    statuses: [
      { name: undefined, title: 'Все статусы', },
      ...$n.status('all'),
    ],
    managers: [],
    status: { name: undefined, title: 'Все статусы', },
    manager: { id: undefined, fullName: 'Все пользователи', },
    orders: [],
    columns: [
      { name: 'tools', title: '', },
      { name: 'createdAt', title: 'Дата', sort: true, },
      { name: 'status', title: 'Статус', },
      { name: 'manager.fullName', title: 'Менеджер', },
      { name: 'number', title: '№ заявки', sort: true, },
      { name: 'customer.title', title: 'Заказчик', },
      { name: 'contact.title', title: 'Баер', },
      { name: 'endedAt', title: 'Срок принятия КП', },
      { name: 'products.length', title: 'Кол-во позиций', },
      { name: 'products[0].title', title: 'Первая позиция', },
      // { name: 'delete', title: '', },
    ],
  }),
  computed: {
    api() {
      return () => $api.app.orders.get().with('products').with('customer').with('manager').with('contact')
    },
  },
  created() {
    this.loadManagers()
  },
  methods: {
    loadManagers() {
      this.$var('loadManagers', true)
      $api.auth.users.get().then((response) => {
        this.managers = [
          { id: undefined, fullName: 'Все пользователи', },
          ...response.data.content,
        ]
      }).finally(() => {
        this.$var('loadManagers', false)
      })
    },
    deleteOrder(value) {
      this.$var('load', true)
      $api.app.orders.delete(value).then(() => {
        this.loadOrders()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    selectStatus(value) {
      this.status = value
      this.params = {
        ...this.params,
        filter: {
          ...this.params.filter,
          status: value.name,
        },
      }
    },
    selectManager(value) {
      this.manager = value
      this.params = {
        ...this.params,
        filter: {
          ...this.params.filter,
          managerId: value.id,
        },
      }
    },
    search(value) {
      this.params = {
        ...this.params,
        filter: {
          ...this.params.filter,
          number: value,
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .orders-list {
    .filters {
      width: 400px;
      margin: 20px 0;
      &>* {
        margin: 10px 0;
      }
    }
  }
</style>
