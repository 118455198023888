<template>
  <div class="tab-product-info">
    <n-loader :loading="$var('load') && !loading" />
    <n-form @submit="submit">
      <n-items>
        <n-input title="№ позиции *" type="number" v-bind="$form.input('number')" />
        <n-input title="Название товара *" v-bind="$form.input('title')" />
        <n-input title="Производитель" v-bind="$form.input('producer')" />
        <n-select title="Категория товара" :data="categories" v-bind="$form.input('category', 'select')" />
        <div class="columns">
          <n-items style="width: 50%">
            <div style="width: 220px"><n-input title="Stock code" v-bind="$form.input('code')" /></div>
            <br />
            <div style="width: 220px"><n-input title="Дата поставки" v-bind="$form.input('deliveryDate')" /></div>
            <div style="width: 420px"><n-input title="Условия и город отгрузки заказчика (инкотермс)" v-bind="$form.input('deliveryTerms')" /></div>
          </n-items>
          <div style="width: 100px"><n-input title="Количество *" v-bind="$form.input('quantity')" /></div>
          <div style="width: 100px"><n-select title="Ед. измерения *" :data="units" v-bind="$form.input('unit', 'select')" /></div>
        </div>
        <div class="columns">
          <div style="width: 50%">
            <upload-images title="Фото товара от заказчика" v-bind="$form.input('image')" :editable="editable" />
            <n-textarea title="Описание товара от заказчика" simple v-bind="$form.input('description')" />
          </div>
          <div style="width: 50%">
<!--            <upload-images title="Фото ЯРС" v-bind="$form.input('imageYars')" />-->
<!--            <n-textarea title="Описание товара от YARS" v-bind="$form.input('descriptionYars')" />-->
          </div>
        </div>
<!--        <div style="width: 60%">-->
<!--          <n-select title="Альтернативный товар для" :data="categories" v-bind="$form.input('category', 'select')" />-->
<!--        </div>-->
      </n-items>
      <n-divide style="margin-top: 30px">
        <div><n-button @click="$emit('close')">Отменить</n-button></div>
        <div><n-button v-if="editable" type="submit" color="success">Сохранить</n-button></div>
      </n-divide>
    </n-form>
  </div>
</template>

<script>
export default {
  name: 'TabProductInfo',
  props: {
    order: { type: Object, default: () => ({}), },
    product: { type: Object, default: () => ({}), },
    loading: { type: Boolean, default: false, },
    editable: { type: Boolean, default: false, },
  },
  data() {
    return {
      categories: [],
      units: [ 'шт', 'ед', 'BO', 'KIT', 'PAI', 'PK', 'BX', 'EA', ],
    }
  },
  watch: {
    product() {
      this.init()
    },
  },
  created() {
    this.init()
    this.loadCategories()
    this.$form.rules({
      number: [ 'required', ],
      title: [ 'required', ],
      quantity: [ 'required', ],
      unit: [ 'required', ],
    })
  },
  methods: {
    init() {
      if (this.product.id) {
        this.$form.init(this.product)
      } else {
        this.$form.init({
          applicationId: this.order.id,
          image: null,
          number: '',
          title: '',
          producer: '',
          code: '',
          deliveryDate: '',
          deliveryTerms: '',
          description: '',
          quantity: '',
          unit: '',
          category: '',
        })
      }
    },
    loadCategories() {
      this.$var('load', true)
      $api.app.categories.get().then((response) => {
        this.categories = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)

        const apis = $n.map([ 'image', 'imageYars', ], (name) => {
          const file = this.$form.get(name)
          if ($n.isString(file) || !file) {
            return { promise: new Promise((e) => e()), name, }
          }
          return { promise: $api.files.create({ file, }), name, }
        })

        $n.promiseObjects(apis).then((responses) => {
          const files = $n.reduce(responses, (result, item) => {
            result[item.name] = item.response?.data?.content?.id
            return result
          }, {})
          this.save(files)
        }).catch((e) => {
          this.$var('load', false)
        })
      }
    },
    save(files) {
      const data = {
        ...this.$form.get(),
        categoryId: this.$form.get('category.id'),
        ...files,
      }
      const api = this.product.id ? $api.app.products.edit(this.product.id, data) : $api.app.products.create(data)
      api.with('category').with('calculation').then((response) => {
        this.$emit('reload')
        if (!this.product.id) {
          this.$emit('close')
        }
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-product-info {
  position: relative;
  padding-top: 20px;
  margin-top: -1px;

  .columns {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    & + .columns {
      margin-top: calc(var(--n-items-margin) * 2);
    }
    &>*:not(:last-child) {
      margin-right: 20px;
    }
  }
}
</style>
