import LayoutApp from 'layouts/App'
import LayoutMain from 'layouts/main/Index'
import LayoutAuth from 'layouts/auth/Index'
import InboxList from 'pages/letters/List'
import InboxCreate from 'pages/letters/Create'
import InboxRequest from 'pages/letters/CreateRequest'
import InboxLetter from 'pages/letters/Letter'
import OrdersList from 'pages/orders/list/Index'
import OrdersIndex from 'pages/orders/index/Index'
import ProvidersList from 'pages/providers/list/Index'
import SendRequest from 'pages/orders/components/SendRequest'
import CustomersList from 'pages/customers/Index'
import Login from 'pages/auth/login/Index'
import UserList from 'pages/users/list/Index'
import UserIndex from 'pages/users/index/Index'
import ProductsList from 'pages/products/list/Index'
import ProfileIndex from 'pages/profile/Index'
import EmailsList from 'pages/emails/List'

export default [
  { path: '', component: LayoutApp, children: [
    
    { path: '', component: LayoutMain, title: 'Входящие письма', children: [
      // Письма
      { path: 'inbox/:folder?', title: 'Письма', name: 'inbox.list', icon: 'envelope', component: InboxList, },
      { path: 'inbox/letters/create', title: 'Новое письмо', name: 'inbox.create', icon: 'plus', component: InboxCreate, parent: 'inbox.list', },
      { path: 'inbox/request/create', title: 'Новое письмо', name: 'inbox.request', icon: 'plus', component: InboxRequest, parent: 'inbox.list', },
      { path: 'inbox/letters/:id', title: 'Письмо', name: 'inbox.letter', icon: 'portrait', component: InboxLetter, parent: 'inbox.list', },
    
      // Заявки
      { path: '', title: 'Заявки', name: 'orders.list', icon: 'paste', component: OrdersList, },
      { path: 'orders/:id', title: 'Заявка', name: 'orders.index', icon: 'file', component: OrdersIndex, parent: 'orders.list', },
        
      // База данных
      { path: 'providers', title: 'Поставщики', name: 'providers.list', icon: 'user-friends', component: ProvidersList, },
      { path: 'customers', title: 'Заказчики', name: 'customers.list', icon: 'user-check', component: CustomersList, },
      { path: 'products', title: 'Товары', name: 'products.list', icon: 'boxes', component: ProductsList, },
      
      
      // Настройки
      { path: 'profile', title: 'Профиль', name: 'profile.index', icon: 'user', component: ProfileIndex, },
      { path: 'users', title: 'Пользователи', name: 'users.list', icon: 'users', component: UserList, },
      { path: 'users/:id', title: 'Карточка пользователя', name: 'users.index', icon: 'portrait', component: UserIndex, parent: 'users.list', },

      { path: 'emails', title: 'Почтовые ящики', name: 'emails.list', icon: 'email', component: EmailsList, },
        
      { path: 'send-request', title: 'Отправить запрос', name: 'sendrequest', icon: 'user-friends', component: SendRequest, },
    
      { path: 'settings', title: 'Настройки', name: 'settings.list', icon: 'cog', component: () => import('pages/settings/Index'), },
    
      { path: 'managers/manager-card/:id', title: 'Карточка менеджера', name: 'managers.card',
        icon: 'address-card', component: () => import('pages/managers/Card'), parent: 'managers.list', },
    ], },
    
    { path: '', component: LayoutAuth, children: [
      { path: 'login', name: 'login', component: Login, },
    ], },
  ], },
]
