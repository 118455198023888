
export default {
  status(status) {
    const statuses = {
      created: { name: 'created', title: 'Заполняется', color: 'success', },
      approving: { name: 'approving', title: 'На проверке', color: 'success', },
      calculation: { name: 'calculation', title: 'В работе', color: 'success', },
      waiting: { name: 'waiting', title: 'Отправлено КП', color: 'warning', },
      failed: { name: 'failed', title: 'Отказ по заявке', color: 'danger', },
      finished: { name: 'finished', title: 'Получено РО', color: 'default', },
      canceled: { name: 'canceled', title: 'Запрос отменен заказчиком', color: 'danger', },
    }
    if (status === 'all') {
      return Object.values(statuses)
    }
    return statuses[status] || { title: 'Статус', color: 'default', }
  },
  editableStatus(status) {
    return status
  },
  closedStatus(status) {
    if (!status) {
      return true
    }
    return [ 'failed', 'finished', 'canceled', ].includes(status)
  },
  
  numberFormat(number, rounded = 2) {
    if (number) {
      if ($n.isString(number)) {
        number = number.replace(',', '.').replace(' ', '') * 1
      }
  
      if ($n.isNumber(number)) {
        let n = number.toString()
        if (n.indexOf('.') !== -1) {
          n = number.toFixed(rounded).toString()
        }
        return n.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
    }
    return ''
  },
  phoneFormat(number) {
    const cleaned = ('' + number).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d+)$/)
    if (match) {
      return '+7 (' + match[2] + ') ' + match[3] + '-' + match[4] + '-' + match[5]
    }
    
    return number
  },
}
