<template>
  <div class="block-calc">
    <div class="head">
      <div class="title">
        <h3>{{ !customs ? 'Расчеты без растаможки' : 'Расчеты с растаможкой' }}</h3>
        <div v-if="editable" class="change" @click="customs = !customs">
          {{ customs ? 'показать без растаможки' : 'показать с растаможкой' }}
        </div>
      </div>
      <div v-if="calculation.id" class="tools">
        <n-button v-if="editable && canRecalc" type="submit" color="primary" @click="save">Пересчитать и сохранить</n-button>
      </div>
    </div>
    <div v-if="!calculation.id" class="empty">Выберете ценовое предложение чтобы приступить к расчетам</div>
    <template v-else>
      <div v-if="!customs" class="columns">
        <div>
          <n-items>
            <n-input disabled title="A1. Цена в валюте предложения" v-bind="$form.input('a1')" />
            <n-input disabled title="A2. Количество" v-bind="$form.input('a2')" />
            <n-input disabled title="A3. Сумма в валюте предложения (A1*A2)" :value="f('a3', v('a1') * v('a2'))" />
            <n-select title="A4. Валюта" :data="currenciesTitles" v-bind="$form.input('currency1', 'select')" :update:value="(v) => updateCurrency(v, 'currency1', 'a5')" />
            <n-input title="A5. Курс" v-bind="$form.input('a5')" type="number" />
            <n-input disabled title="A6. Сумма в KZT (A3*A5)" :value="f('a6', v('a3') * v('a5'))" />
            <n-input title="A7. Доставка на весь объем в KZT (до склада)" v-bind="$form.input('a7')" type="number" />
            <n-input title="A8. Доставка на весь объем в KZT (до заказчика)" v-bind="$form.input('a8')" type="number" />
          </n-items>
        </div>
        <div>
          <n-items>
            <n-input disabled title="B1. Итого, KZT (A6+A7+A8)" :value="f('b1', v('a6') + v('a7') + v('a8'))" />
            <n-input disabled title="B2. Цена за единицу в KZT (B1/A2)" :value="f('b2', v('b1') / v('a2'))" />
            <n-input disabled title="B3. Цена за единицу в валюте КП (B2/B5)" :value="f('b3', v('b2') / v('b5'))" />
            <n-select title="B4. Валюта КП" :data="currenciesTitles" v-bind="$form.input('currency2', 'select')" :update:value="(v) => updateCurrency(v, 'currency2', 'b5')" />
            <n-input title="B5. Курс КП" v-bind="$form.input('b5')" type="number" />
            <n-input title="B6. Коэфициент наценки" v-bind="$form.input('b6')" type="number" />
            <n-input disabled title="B7. Цена для заказчика за единицу в валюте КП (B3*B6)" :value="f('b7', v('b3') * v('b6'))" />
            <n-input disabled title="B8. Сумма для заказчика в валюте КП (A2*B7)" :value="f('b8', v('a2') * v('b7'))" />
          </n-items>
        </div>
      </div>
      <div v-else class="columns">
        <div>
          <n-items>
            <n-input disabled title="A1. Цена в валюте предложения" v-bind="$form.input('c_a1')" />
            <n-input disabled title="A2. Количество" v-bind="$form.input('c_a2')" />
            <n-input disabled title="A3. Сумма в валюте предложения (A1*A2)" :value="f('c_a3', v('c_a1') * v('c_a2'))" />
            <n-input title="A4. Расходы на упаковку и сертификацию в валюте предложения (если указаны отдельно)" v-bind="$form.input('c_a4')" type="number" />
            <n-input disabled title="A5. Итого по предложению поставщика, в валюте (A3+A4)" :value="f('c_a5', v('c_a3') + v('c_a4'))" />
            <n-input title="A6. Доставка до КЗ в валюте (с учетом оформления экспортной декларации, если оформляем сами)" v-bind="$form.input('c_a6')" type="number" />
            <n-select title="A7. Валюта" :data="currenciesTitles" v-bind="$form.input('currency1', 'select')" :update:value="(v) => calcCurrency(v, 'currency1')" />
            <n-input title="A8. Ставка таможенной пошлины (в %)" v-bind="$form.input('c_a8')" type="number" />
            <n-input disabled title="A9. Сумма пошлины в валюте ((A5+A6)*(A8/100))" :value="f('c_a9', (v('c_a5') + v('c_a6')) * v('c_a8') / 100)" />
            <n-input disabled title="A10. Итого за растаможенный товар в валюте (A5+A6+A9)" :value="f('c_a10', v('c_a5') + v('c_a6') + v('c_a9'))" />
          </n-items>
        </div>
        <div>
          <n-items>
            <n-input title="B1. Услуги брокера + СВХ в валюте предложения поставщика" v-bind="$form.input('c_b1')" type="number" />
            <n-input title="B2. Сертификация (или декларирование, или отказное письмо) в валюте предложения поставщика" v-bind="$form.input('c_b2')" type="number" />
            <n-input disabled title="B3. Итого за товар, в валюте предложения поставщика (A10+B1+B2)" :value="f('c_b3', v('c_a10') + v('c_b1') + v('c_b2'))" />
            <n-input title="B4. Доставка по КЗ в валюте предложения поставщика (до склада)" v-bind="$form.input('c_b4')" />
            <n-input title="B5. Доставка в валюте предложения поставщика (до заказчика)" v-bind="$form.input('c_b5')" />
            <n-input disabled title="B6. Итого, с учетом доставки, в валюте предложения поставщика (B3+B4+B5)" :value="f('c_b6', v('c_b3') + v('c_b4') + v('c_b5'))" />
            <n-input disabled title="B7. Цена за единицу в валюте предложения поставщика (B6/A2)" :value="f('c_b7', v('c_b6') / v('c_a2'))" />
            <n-select title="B8. Валюта КП" :data="currenciesTitles" v-bind="$form.input('currency2', 'select')" :update:value="(v) => calcCurrency(v, 'currency2')" />
            <n-input title="B9. Курс КП" v-bind="$form.input('c_b9')" type="number" />
            <n-input disabled title="B10. Цена за единицу в валюте КП (B7/B9)" :value="f('c_b10', v('c_b7') / v('c_b9'))" />
            <n-input title="B11. Коэфициент наценки" v-bind="$form.input('c_b11')" type="number" />
            <n-input disabled title="B12. Цена для заказчика за единицу в валюте КП (B10*B11)" :value="f('c_b12', v('c_b10') * v('c_b11'))" />
            <n-input disabled title="B13. Сумма для заказчика в валюте КП (A2*B12)" :value="f('c_b13', v('c_a2') * v('c_b12'))" />
          </n-items>
        </div>
      </div>
      <n-divide style="margin-top: 30px">
        <div><n-button @click="$emit('close')">Закрыть</n-button></div>
        <div><n-button v-if="editable && canRecalc" type="submit" color="primary" @click="save">Пересчитать и сохранить</n-button></div>
      </n-divide>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BlockCalc',
  props: {
    order: { type: Object, default: () => ({}), },
    calculation: { type: Object, default: () => ({}), },
    currencies: { type: Array, default: () => [], },
    editable: { type: Boolean, default: false, },
  },
  data() {
    return {
      canRecalc: false
    }
  },
  computed: {
    customs: {
      get() {
        return this.$form.get('customs')
      },
      set(value) {
        this.$form.set('customs', value)
        this.initCurrency()
      },
    },
    currenciesTitles() {
      return this.currencies.map((i) => i.title)
    },
  },
  watch: {
    calculation() {
      this.init()
    },
  },
  created() {
    this.init()
    const statuses = [ 'waiting', 'failed', 'finished', 'canceled', ]
    this.canRecalc = !statuses.includes(this.order.status)
  },
  methods: {
    v(k) {
      return this.$form.get(k) * 1
    },
    f(k, v) {
      if (this.$form.get(k) !== v) {
        this.$form.set(k, v)
      }
      return $n.numberFormat(v)
    },
    init() {
      if (this.calculation.id) {
        this.$form.init({
          customs: false,
          ...this.calculation,
        })
        this.initCurrency()
      }
    },
    initCurrency() {
      if (!this.calculation.currency1) {
        if (this.customs) {
          this.calcCurrency(this.calculation.priceOffer.currency, 'currency1')
        } else {
          this.updateCurrency(this.calculation.priceOffer.currency, 'currency1', 'a5')
        }
      }
      if (!this.calculation.currency2) {
        if (this.customs) {
          this.calcCurrency(this.order.currency, 'currency2')
        } else {
          this.updateCurrency(this.order.currency, 'currency2', 'b5')
        }
      }
    },
    updateCurrency(value, currencyInput, courseInput) {
      this.$form.set(currencyInput, value)
      if (value && this.currenciesTitles.includes(value)) {
        const cur = $n.find(this.currencies, [ 'title', value, ])
        if (cur) {
          this.$form.set(courseInput, cur.value)
        }
      }
    },
    calcCurrency(value, currencyInput) {
      this.$form.set(currencyInput, value)
      
      const c1 = this.$form.get('currency1')
      const c2 = this.$form.get('currency2')
      if (c1 && c2 && this.currenciesTitles.includes(c1) && this.currenciesTitles.includes(c2)) {
        const cur1 = $n.find(this.currencies, [ 'title', c1, ])
        const cur2 = $n.find(this.currencies, [ 'title', c2, ])
        if (cur1 && cur1) {
          this.$form.set('c_b9', cur2.value / cur1.value)
        }
      }
    },
    save() {
      this.$emit('update:calculation', this.$form.get())
    },
  },
}
</script>

<style lang="scss" scoped>
.block-calc {
  margin-top: 40px;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      display: flex;
      align-items: baseline;
      .change {
        margin-left: 20px;
        cursor: pointer;
        text-decoration: underline;
        font-size: .8em;
        opacity: .9;
      }
    }
  }

  .empty {
    font-size: .9em;
    opacity: .9;
    font-style: italic;
  }

  .columns {
    margin-top: 20px;
    display: flex;
    flex-wrap: nowrap;
    &>* {
      width: 100%;
    }
    &>*:not(:last-child) {
      margin-right: 20px;
    }
  }
}
</style>
