<template>
  <div class="page-emails-list">
    <n-card :loading="$var('load')">
      <div class="table-tools">
        <n-divide>
          <div></div>
          <div><n-button @click="$var('create', true)">Новый почтовый ящик</n-button></div>
        </n-divide>
      </div>
      <n-table :data="emails" :columns="columns">
        <template #imap="{item}">
          {{ item.imap }}:{{ item.imapPort }}
        </template>
        <template #smtp="{item}">
          {{ item.smtp }}:{{ item.smtpPort }}
        </template>
        <template #tools="{item}">
          <n-button icon="pen" round @click="$var('edit', item)" />
        </template>
      </n-table>
    </n-card>

    <modal-email-create v-if="$var('create')" @reload="load" @close="$var('create', false)" />
    <modal-email-edit v-if="$var('edit')" :email="$var('edit')" @reload="load" @close="$var('edit', false)" />
  </div>
</template>

<script>
import ModalEmailCreate from './Create'
import ModalEmailEdit from './Edit'

export default {
  name: 'PageEmailsList',
  components: { ModalEmailEdit, ModalEmailCreate, },
  data: () => ({
    emails: [],
    columns: [
      { name: 'email', title: 'E-mail', },
      { name: 'imap', title: 'IMAP', },
      { name: 'smtp', title: 'SMTP', },
      { name: 'from', title: 'Поле От', },
      { name: 'tools', title: '', },
    ],
  }),
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.email.emails.get().then((response) => {
        this.emails = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-emails-list {

}
</style>
