<template>
  <div class="page-profile-index">
    <n-card>
      <h2>Подписи</h2>

      <div class="table-tools">
        <n-divide>
          <div></div>
          <div><n-button @click="$var('sign', true)">Создать</n-button></div>
        </n-divide>
      </div>
      <n-table :data="signs" :columns="columns" :loading="$var('load')">
        <template #content="{item}">
          <div v-html="item.content" />
        </template>
        <template #tools="{item}">
          <n-button icon="pen" @click="$var('sign', item)" />
        </template>
      </n-table>
    </n-card>

    <n-card :loading="$var('loadPass')">
      <h2>Смена пароля</h2>
      <n-form @submit="changePass">
        <n-items>
          <n-input title="Старый пароль" v-bind="$form.input('oldPassword')" />
          <n-input title="Новый пароль" v-bind="$form.input('password')" />
          <n-input title="Новый пароль еще раз" v-bind="$form.input('passwordRepeat')" />
          <n-button type="submit">Сохранить</n-button>
        </n-items>
      </n-form>
    </n-card>

    <modal-sign v-if="$var('sign')" :sign="$var('sign')" @reload="load" @close="$var('sign', false)" />
  </div>
</template>

<script>
import ModalSign from './ModalSign'

export default {
  name: 'PageProfileIndex',
  components: { ModalSign, },
  data() {
    return {
      signs: [],
      columns: [
        { name: 'title', title: 'Название', },
        { name: 'content', title: 'Подпись', },
        { name: 'tools', title: '', },
      ],
    }
  },
  created() {
    this.load()
    this.$form.init({
      oldPassword: '',
      password: '',
      passwordRepeat: '',
    })
    this.$form.rules({
      oldPassword: [ 'required', ],
      password: [ 'required', ],
      passwordRepeat: [ 'required', ],
    })
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.auth.signs.get($app.auth.user().id).then((response) => {
        this.signs = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    changePass() {
      if (!this.$form.check()) {
        return
      }
      if (this.$form.get('password') !== this.$form.get('passwordRepeat')) {
        setTimeout(() => {
          this.form_errors = { default: { passwordRepeat: [ 'Пароли не совпадают', ], }, }
        }, 1)
        return
      }

      this.$var('loadPass', true)
      $api.auth.users.password($app.auth.user().id, this.$form.get()).then((response) => {
        this.$form.init({
          oldPassword: '',
          password: '',
          passwordRepeat: '',
        })
      }).finally(() => {
        this.$var('loadPass', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-profile-index {

}
</style>
