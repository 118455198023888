<template>
  <n-modal class="modal-email-edit" :loading="$var('load')" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <h3>Редактировать почтовый ящик</h3>
        <n-input title="email" v-bind="$form.input('email')" />
        <n-input title="imap" v-bind="$form.input('imap')" />
        <n-input title="imapPort" v-bind="$form.input('imapPort')" />
        <n-input title="smtp" v-bind="$form.input('smtp')" />
        <n-input title="smtpPort" v-bind="$form.input('smtpPort')" />
        <n-input title="Имя От" v-bind="$form.input('fromTitle')" />
        <n-input title="password" v-bind="$form.input('password')" />
      </n-items>
      <div class="footer-buttons">
        <n-button @click="$emit('close')">Отмена</n-button>
        <n-button color="success" type="submit">Сохранить</n-button>
      </div>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalEmailEdit',
  props: {
    email: { type: Object, required: true, },
  },
  data() {
    return {
    }
  },
  created() {
    this.$form.init(this.email)
    this.$form.rules({
      email: [ 'required', ],
      imap: [ 'required', ],
      imapPort: [ 'required', ],
      smtp: [ 'required', ],
      smtpPort: [ 'required', ],
      fromTitle: [ 'required', ],
      password: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.email.emails.edit(this.email.id, this.$form.get()).then((response) => {
          this.$emit('reload')
          this.$emit('close')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-email-edit {
}
</style>
