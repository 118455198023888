<template>
  <n-modal class="modal-add-document" :loading="$var('load')" @close="$emit('close')">
    <h3>Загрузить документ</h3>
    <n-form @submit="submit">
      <n-items>
        <n-upload multi title="Выберите Документ" :value.sync="file" />
      </n-items>
      <n-divide style="margin-top: 20px">
        <div><n-button @click="$emit('close')">Отменить</n-button></div>
        <div><n-button type="submit" color="success" :disabled="!file">Сохранить</n-button></div>
      </n-divide>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalAddDocument',
  props: {
    type: { type: String, default: 'application', },
    order: { type: Object, default: () => ({}), },
  },
  data: () => ({
    file: null,
  }),
  methods: {
    submit() {
      if (this.file) {
        this.$var('load', true)
        $api.files.create({ file: this.file[0], }).then((response) => {
          const data = {
            file: response.data.content.id,
            modelType: this.type,
            modelId: this.order.id,
            title: this.file[0].name,
          }
          return $api.app.document.create(data).then((response) => {
            this.$emit('submit')
            this.$emit('close')
          }).finally(() => {
            this.$var('load', false)
          })
        }).catch(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-add-document {
}
</style>
